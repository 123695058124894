<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Action</th>
          <!-- <th width="7%">
        Check
        </th> -->
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.purchasePaymentProposal"
            :class="tr.class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <div class="mt-4 flex justify-between">
                  <vx-tooltip text="Show Invoice" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                </div>
              </template>
            </vs-td>
            <!-- <vs-td>
            <vx-tooltip text="" class="mr-4">
              <vs-checkbox
               :checked="checked.includes(data[indextr].ID)"
                v-on:click="addChecked(data[indextr])"
                >Check</vs-checkbox
              >
            </vx-tooltip>
          </vs-td> -->
            <vs-td>
              {{ tr.Code }}
            </vs-td>
            <vs-td>
              Code : {{ tr.SupplierCode }}<br />
              Name : {{ tr.SupplierName }}
            </vs-td>
            <vs-td>
              {{ tr.DeliveryNumber }}
            </vs-td>
            <vs-td>
              External Code : {{ tr.PurchaseInvoiceCode }}<br />
              Internal Code : {{ tr.PurchaseInvoiceCodeInternal }}<br />
              Total Value : {{ priceFormat(tr.PurchaseInvoiceValue) }}<br />
              Due Date : {{ dateFormat(tr.DueDate) }}<br />
              Inv Date : {{ dateFormat(tr.InvoiceDate) }}<br />
              Plan Payment Date : {{ dateFormat(tr.PurposePaymentDate) }}
            </vs-td>
            <vs-td> {{ priceFormat(tr.PaidValue) }}<br /> </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div @close="handleClose" :selected="selectedData" />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import detail from "./detail.vue";
import moment from "moment";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
  },
  props: {
    selected: Object,
    option: Object,
    warehouse_code: {
      type: String,
    },
    from_inv_date: {
      type: String,
    },
    to_inv_date: {
      type: String,
    },
    from_due_date: {
      type: String,
    },
    to_due_date: {
      type: String,
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
        warehouse_code: this.warehouse_code,
        from_inv_date: this.from_inv_date,
        to_inv_date: this.to_inv_date,
        from_due_date: this.from_due_date,
        to_due_date: this.to_due_date,
      },
      checked: [],
      checkedCode: [],
      confirmation: false,
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "No Shipment",
          value: "delivery_number",
        },
        {
          text: "Invoice",
          value: "purchase_invoice_code",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.reloadData(this.params);
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    // closeDetail() {
    //   this.activePrompt2 = false;
    //   this.detail = false;
    //   this.selectedData.class = "";
    //   this.selectedData = {
    //     ID: 0,
    //   };
    //   this.reloadData(this.params);
    // },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-proposal", {
            params: {
              status: 1,
              still_empty: 1,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              warehouse_code: this.warehouse_code,
              from_inv_date: this.from_inv_date,
              to_inv_date: this.to_inv_date,
              from_due_date: this.from_due_date,
              to_due_date: this.to_due_date,
              po_type: "non-trade"
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    handleClose() {
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    reloadData(params) {
      this.params = params;
      console.log("reload data");
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        // console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentProposal;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentProposal.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>