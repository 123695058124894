<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="GR Code"
          name="GR Code"
          v-model="gr_codes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly"
            label="Invoice Date"
            name="Invoice Date"
            v-model="table.inv_date"
            placeholder="Invoice Date"
            readonly
          />
        </div>
      </div>
    </div>
    <div v-if="createdDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Created Date"
        name="Created Date"
        v-model="createdDate"
        readonly
      />
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly"
            label="Payment Term"
            name="Payment Term"
            v-model="table.payment_term_name"
            placeholder="Payment Term"
            readonly
          />
        </div>
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <div class="vx-col w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full input-readonly"
              label="Bank Name"
              name="Bank Name"
              v-model="table.bank_name"
              placeholder="Bank Name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Number"
          name="Tax Number"
          v-model="tr_number"
          placeholder="Tax Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Date"
          name="Tax Date"
          v-model="tr_date"
          placeholder="Tax Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Currency Code"
          name="Currency Code"
          v-model="table.currency_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty</vs-th>
        <vs-th>Total Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <div style="display: none">
            {{ (tax_item[indextr] = data[indextr].tax) }}
            {{ (net[indextr] = data[indextr].net) }}
          </div>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5"
                >Received :
                {{
                  data[indextr].qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ data[indextr].item_unit }}</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">
                PO : {{ data[indextr].qty_po }} {{ data[indextr].item_unit }}<br>
                INV : {{ data[indextr].quantity }} {{ data[indextr].item_unit }}
              </span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">
                PO : {{ data[indextr].total_price_po }}<br>
                INV : {{ data[indextr].net }}
              </span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">
                PO : {{ data[indextr].discount_po }}<br>
                INV : {{ data[indextr].discount }}
              </span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">
                PO : {{ data[indextr].net_po }}<br>
                INV : {{ data[indextr].subtotal }}
              </span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">
                PO : {{ data[indextr].tax_po }}<br>
                INV : {{ data[indextr].tax }}
              </span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value"
        name="Item Value"
        v-model="table.item_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value"
        name="Charges Value"
        v-model="table.charges_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value"
        name="Tax Value"
        v-model="table.tax_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value"
        name="Discount Value"
        v-model="table.discount_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value"
        name="Total Invoice Value"
        v-model="table.total_inv_value"
        readonly
      />
    </div>

    <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
    <template v-for="note in notes">
      <b v-bind:key="note">{{note}}
        <br>
      </b>
    </template>
    <vs-divider style="width: 100%; margin-left: 2%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="handleShowAttachment(tr)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          discount_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        gr_codes: "",
        tax_item: [],
        tax_charges: [],
        charges: [],
        net: [],
        total: [],
        taxx: [0, 0],
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        this.downloadFileAwsS3(selected.path);
      }
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.inv_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.inv_date))).format("DD/MM/YYYY")
                : null;
            this.table.payment_term_name = resp.data.payment_term_name;
            this.gr_codes = resp.data.gr_codes;
            this.notes = resp.data.supplier_invoice_notes.toString().split(";")
            this.table.currency_code = resp.data.currency_code;
            this.table.bank_name = resp.data.bank_name + " - " + resp.data.account_number + " " + resp.data.account_name;
            this.tr_date = moment(String(resp.data.tr_date)).format("YYYY-MM-DD") == "0001-01-01" ? "" : moment(String(new Date(resp.data.tr_date))).format("DD/MM/YYYY");
            this.tr_number = resp.data.tr_number;
            this.table.total_inv_value = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value = resp.data.item_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value = resp.data.charge_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.discount_value = resp.data.total_discount
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;

            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount: element.discount_inv
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                amount_uom: element.amount_uom,
                qty_received: element.qty_received,
                tax_rate: element.tax_rate,
                subtotal: (element.net - element.discount_inv).toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                total_price_po: (parseFloat(element.price_po) * parseFloat(element.qty_po))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                net_po: (parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax_po: ((parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount)) * (element.tax_rate/100))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount_po: element.discount
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                qty_po: element.qty_po
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value: element.value
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
              });
            });

            resp.data.data_attachment.map((r,i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path
              })
            })
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.piId) {
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>